.appbtn {
  padding: 10px;
  background-color: #222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center
}
.reverse {
  flex-direction: row-reverse;
}
.appbtn i {
  color: #fff;
}

.reverse i {
  margin-left: 15px;
}

.appbtn:hover {
  background-color: #333
}
.logincont .googlebtn{
  background-color: #dd4b39;
  color: #fff;
  border: solid 1px rgba(0, 0, 0, 0);
  display: flex;
 }
 .diflogin {
   display: flex;
   justify-content: center;
   height: 35px;
   align-items: center;
 }
 .diflogin i {
   position: absolute;
   left: 25px
 }
 .logincont .googlebtn:hover, .logincont .facebookbtn:hover {
   filter: brightness(95%);
 }
 
 .logincont .facebookbtn {
  background-color: #4c69ba;
  color: white;
  border: solid 1px rgba(0, 0, 0, 0)
 
 }
 .logincont .facebookbtn i {
  color: white;
 }
 
 .logincont .facebookbtn:active,.logincont .googlebtn:active {
   transform: scale(0.98)
 }
 .outline {
  background-color: #fff;
  color: #000;
  border: solid 1px #222
}
 .outline:hover {
  background-color: #222;
  color: #fff;
  border: solid 1px transparent
}
.appbtn:disabled {
  cursor: no-drop;
}
.appbtn.hide {
  opacity: 0;
  pointer-events: none
}