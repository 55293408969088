.slides {
  height: 100%;
  max-width: 100%; 
}

.slides .slide {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  padding-left: 10%;
}
.slidetitles {
  display: flex;
  flex-direction: column;
  gap: 15px
}

.slides .slide.active {
  max-width:100%;
  opacity: 1;
}
.slidecont {
  max-width: 100%; /* Ensures the container does not exceed the width of its parent */
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 1;
  transform: translateY(50px);
  transition: all 0.7s;
  opacity: 0;
  transition-delay: 0.4s
}
.white .slidecont {
  color: #fff
}
.white .slidecont button {
  background-color: #fff;
  color: #000
}
.active .slidecont {
  transform: initial;
  opacity: 1;
}
.slidecont > * {
  transition: all 0.3s;
}
.slide img {
  display: flex;
  height: auto;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.imging {
  display:flex;
  max-width: 100%; /* Ensures the image never exceeds the width of its container */
  height: auto; /* Allows the image to maintain its aspect ratio */
  display: block; /* Ensures no extra space under the image */
  margin: 0 auto; /* Centers the image horizontally */
}


.slide button {
  flex-direction: row-reverse;
}
.slide .appbtn i {
  margin-left: 10px
}
.slide .appbtn:hover i {
margin-left: 20px
}
.pause i:hover {
  color: var(--theme-color)
}
.leftimg {
  display: flex;
  gap: 10px
}
.leftimg .slidecont .slidetitles {
  flex-direction: column-reverse
}
.leftimg .img2 {
  max-width: 100%;
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  transition: all 1s;
  transform: translateX(-80px);
}
.active .img2 {
  left: 0;
  max-width:100%;
  height: auto; /* Allows the image to maintain its aspect ratio */
  display: block; /* Ensures there's no extra space around the image */
  margin: 0 auto; /* Centers the image horizontally within its container */
  opacity: 1;
  transform: translateX(0);
}
.img2 img {
  widows: 100%;
  z-index: 1;
  object-fit: contain;
  max-height: 6;
  max-height: 400px;

  top: 50%;
  transform: translateY(-50%);
}
.slides .center  {
  justify-content: center;
  height: 80%;
}
.center .slidecont {
  text-align: center
}
.center .slidecont a {
  justify-content: center;
  display: flex;
}