.benefit {
  display: flex;
  flex-direction: column;
  width: 150px;
  gap: 10px;
  padding: 20px 15px;
  height: 150px;
  max-width: 100%;
  background-color: #edf2f7;
  border-radius: 4px;
  transition: all 0.3s
}
.benefit h3 {
  text-transform: uppercase;
  font-size: 14px
}
.benefit:hover {
  background-color: rgb(240, 240, 240)
}
.benefit span {
  font-size: 12px
}
.benefit i {
  font-size: 20px;
}